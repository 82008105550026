<template>
  <div>
    <div class="contentFrame">
      <div class="leftSide">
        <div class="subinfotext">サンタクロースからの手紙封筒・便箋ギャラリー</div>

        <div class="sentent_r1">
          フィンランドのサンタクロースからの心温まるお手紙を、日本の子どもたちへお届けしています。<br />

          このページではサンタクロース大使館が、現在までにお届けした過去の封筒をご覧いただけます。
        </div>

        <div class="historyone">
          <!--<h3>2020</h3>-->

          <div class="centeimg2">
            <img src="../../assets/images/history/2022.jpg" alt="" />
          </div>
        </div>
        <div class="historyone">
          <!--<h3>2020</h3>-->

          <div class="centeimg2">
            <img src="../../assets/images/history/2021.jpg" alt="" />
          </div>
        </div>
        <div class="historyone">
          <!--<h3>2020</h3>-->

          <div class="centeimg2">
            <img src="../../assets/images/history/2020.jpg" alt="" />
          </div>
        </div>

        <div class="historyone">
          <!--<h3>2019</h3>-->

          <div class="centeimg2">
            <img src="../../assets/images/history/2019.jpg" alt="" />
          </div>
        </div>

        <div class="historyone">
          <!--<h3>2018</h3>-->

          <div class="centeimg2">
            <img src="../../assets/images/history/2018.png" alt="" />
          </div>
        </div>

        <div class="historyone">
          <!--<h3>2017</h3>-->

          <div class="centeimg2">
            <img src="../../assets/images/history/2017.png" alt="" />
          </div>
        </div>

        <div class="historyone">
          <!--<h3>2016</h3>-->

          <div class="centeimg2">
            <img src="../../assets/images/history/2016.png" alt="" />
          </div>
        </div>

        <div class="historyone">
          <!--<h3>2015</h3>-->

          <div class="centeimg2">
            <img src="../../assets/images/history/2015.png" alt="" />
          </div>
        </div>

        <div class="historyone">
          <!--<h3>2014</h3>-->

          <div class="centeimg2">
            <img src="../../assets/images/history/2014.png" alt="" />
          </div>
        </div>

        <div class="historyone">
          <!--<h3>2013</h3>-->

          <div class="centeimg2">
            <img src="../../assets/images/history/2013.png" alt="" />
          </div>
        </div>

        <div class="historyone">
          <h3>2012</h3>

          <div class="centeimg1">
            <img src="../../assets/images/history/2012.jpg" alt="" />
          </div>
        </div>

        <div class="historyone">
          <h3>2011</h3>

          <div class="centeimg1">
            <img src="../../assets/images/history/2011.jpg" alt="" />
          </div>
        </div>

        <div class="historyone">
          <h3>2010</h3>

          <div class="centeimg1">
            <img src="../../assets/images/history/2010.jpg" alt="" />
          </div>
        </div>

        <div class="historyone">
          <h3>2007-2009</h3>

          <div class="centeimg1">
            <img src="../../assets/images/history/2007.jpg" alt="" />
          </div>
        </div>

        <div class="historyone">
          <h3>2006</h3>

          <div class="centeimg1">
            <img src="../../assets/images/history/2006.jpg" alt="" />
          </div>
        </div>

        <div class="historyone">
          <h3>2005</h3>

          <div class="centeimg1">
            <img src="../../assets/images/history/2005.jpg" alt="" />
          </div>
        </div>

        <div class="historyone">
          <h3>2004</h3>

          <div class="centeimg1">
            <img src="../../assets/images/history/2004.jpg" alt="" />
          </div>
        </div>
        <div class="historyone">
          <h3>2003</h3>

          <div class="centeimg1">
            <img src="../../assets/images/history/2003.jpg" alt="" />
          </div>
        </div>
        <div class="historyone">
          <h3>2002</h3>

          <div class="centeimg1">
            <img src="../../assets/images/history/2002.jpg" alt="" />
          </div>
        </div>
        <div class="historyone">
          <h3>2001</h3>

          <div class="centeimg1">
            <img src="../../assets/images/history/2001.jpg" alt="" />
          </div>
        </div>
        <div class="historyone">
          <h3>2000</h3>

          <div class="centeimg1">
            <img src="../../assets/images/history/2000.jpg" alt="" />
          </div>
        </div>
        <div class="historyone">
          <h3>1999</h3>

          <div class="centeimg1">
            <img src="../../assets/images/history/1999.jpg" alt="" />
          </div>
        </div>
        <div class="historyone">
          <h3>1998</h3>

          <div class="centeimg1">
            <img src="../../assets/images/history/1998.jpg" alt="" />
          </div>
        </div>
        <div class="historyone">
          <h3>1997</h3>

          <div class="centeimg1">
            <img src="../../assets/images/history/1997.jpg" alt="" />
          </div>
        </div>
        <div class="historyone">
          <h3>1996</h3>

          <div class="centeimg1">
            <img src="../../assets/images/history/1996.jpg" alt="" />
          </div>
        </div>
      </div>
      <div class="rightSide">
        <sideContents />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created: function () {
    this.$parent.middle = 2;
  },
};
</script>

<style scoped lang="scss">
$pc: 1024px; // PC
$tab: 680px; // タブレット
$sp: 480px; // スマホ
@mixin pc {
  @media (max-width: ($pc)) {
    @content;
  }
}

@mixin tab {
  @media (max-width: ($tab)) {
    @content;
  }
}

@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}
.historyone {
  border-bottom: solid 1px #ccc;
  padding: 1em 0;
  h3 {
    font-size: 2em;
  }
}
.subinfotext {
  color: #454552;
  font-size: 1.5em;
  font-weight: bold;
  height: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../../assets/images/ilst1.png") no-repeat left center /
    contain;
  @include sp {
    padding-left: 10vw;
    font-size: 1em;
  }
}
.sentent_r1 {
  color: #d72e23;
  line-height: 1.7;
  margin: 2em 0;
}
.sentent_g1 {
  text-align: left;
  color: #454552;
  line-height: 1.7;
  margin: 2em 0;
}
.subsent2 {
  margin-bottom: 2em;
  text-align: left;
  border: solid 3px #d72e23;
  padding: 1em;
  h3 {
    color: #d72e23;
    padding: 2em 0;
  }
  h4 {
    color: #d72e23;
    margin: 1em 0;
    padding-left: 1em;
    border-left: double 10px #d72e23;
  }
}
.centeimg1 {
  padding: 1em 0;
  text-align: center;
  img {
    width: 33%;
    max-width: 100%;
    @include sp {
      width: 80%;
    }
  }
}
.centeimg2 {
  padding: 1em 0;
  text-align: center;
  img {
    width: 90%;
    max-width: 100%;
  }
}
</style>